@mixin iconfont {
  font-family: $iconfont;
  font-size: $font-size-normal;
  vertical-align: middle;

  -webkit-font-smoothing: antialiased;
}

h1 {
  font-size: 2.25em;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 0.75em;
}

h3 {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 1em;
  text-transform: uppercase;
}

h4 {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 1.25em;
}

h5 {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 1.5em;
}

h6 {
  font-size: 1em;
  font-weight: 600;
}

p {
  margin: 0 0 1.5em;
}

a {
  border-bottom: 1px solid rgba(#404040, 0.4);
  color: #404040;
  text-decoration: none;
  transition: border-bottom 250ms;

  &:focus,
  &:hover {
    border-bottom: 1px solid #404040;
  }
}

blockquote {
  margin: 1.5em;
  color: $color-text-medium;
  font-style: italic;
}

strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

abbr,
acronym {
  border-bottom: 1px dotted $color-background-medium;
}

address {
  margin: 0 0 1.5em;
  font-style: italic;
}

.first {
  margin-left: 0;
  padding-left: 0;
}

.last {
  margin-right: 0;
  padding-right: 0;
}

article {
  h2 {
    margin-bottom: $line-height * 0.5;
  }

  h4 {
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: $line-height * 0.25;
  }

  h5 {
    line-height: $line-height;
    margin-bottom: $line-height * 0.5;
  }

  p,
  ul,
  ol {
    font-size: 1.2em;
    line-height: $line-height;
  }

  hgroup,
  section {
    border-bottom: 1px solid $color-background-medium;
    margin-bottom: $line-height;
    overflow: hidden;
    padding-bottom: $line-height * 0.5;
  }

  ol {
    list-style: decimal inside;
  }
}
